<template>
  <div class="about">
      <div class="about-container">
        <div class="about-content">
          <h1>
            about page
          </h1>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  metaInfo(){
      return {
          titleTemplate: 'REDHOT | ABOUT',
      }
  },

}
</script>

<style scoped>
@media all and (min-width: 768px) {
  .about, .wrapper {
    width: 100vw;
    height: 100vh;
  }
  
  /* about 내용  */
  .about-container {
    width: 100vw;
    height: 80vh;
    display: table;
  }
  .about-container .about-content {
    width: 90vw;
    height: 80vh;
    padding: 10vh 0 0 10vw;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: -1;
  }
  .about-content h1 {
    margin: 0 0 14vh;
    font-size: 2rem;
    line-height: 2.5rem;
    color: #222;
  }

}

@media all and (max-width: 767px) {
.about {
    width: 100vw;
    height: 700px;
  }

  /* about 내용  */
  .about-container {
    width: 76vw;
    margin: 210px auto 0;
  }
  .about-container .about-content {
    width: 78vw;
    position: relative;
    text-align: center;
    z-index: -1;
    word-break: keep-all;
  }

  .about-content h1 {
    margin: 0 0 14vh;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #222;
  }

}
</style>
